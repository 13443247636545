import React from "react"
import {graphql} from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import PageTemplate from "../components/pagetemplate"
import Helmet from "react-helmet"



const Page = ({data}) => {
    const { markdownRemark: page } = data
    const image = page.frontmatter.thumbnail
    let title = page.frontmatter.title
    title = title.replace(/ /g, '')

    return (
        <div className={`page ${image} ${title}`}>
            <div className="double-bg"></div>
            <div className={`bg page-bg ${image}-bg ${title}-bg`}></div>
            <Helmet title={page.frontmatter.title}>
            <link rel="stylesheet" href="https://use.typekit.net/bjf1uex.css" />
            </Helmet>
            <Header siteTitle={page.frontmatter.title}/>
                <main>
                    <PageTemplate 
                        title={page.frontmatter.title}
                        content={page.html}
                        cols={8}
                    />
                </main>
            <Footer />
      </div>
    )
}

export default Page

export const pageQuery = graphql`
query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      title
      thumbnail
    }
  }
}
`
